<template>
  <div v-if="id">
    <b-overlay :show="dataLoading" rounded="sm">

      <b-card
          v-if="$store.state.app.order"
          no-body
          class="mb-2"
      >

        <!-- Шапка таблицы -->
        <div class="m-2">
          <b-row class="justify-content-between">

            <!-- Показывать... -->
            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показывать</label>
              <v-select
                  v-model="pagination.perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pagination.options"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
            </b-col>
          </b-row>

        </div>

        <!--  Таблица  -->
        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="$store.state.app.order.products"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Нет подходящих результатов"
            :sort-desc.sync="isSortDirDesc"
        >

          <!-- Артикул -->
          <template #cell(vendor_code)="data">
            <b-media vertical-align="center">
              {{ data.item.product.vendor_code }}
            </b-media>
          </template>

          <!-- Фото -->
          <template #cell(image)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="32"
                    :src="getImageUrl(data.item.product)"
                    :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                />
              </template>
            </b-media>
          </template>

          <!-- Название -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              {{ data.item.product.name }}
            </b-media>
          </template>

          <!-- Количество -->
          <template #cell(count)="data">

            <BlSpinButton
                :value="data.item.count"
                :max="999999"
                @input="changeOrderItemCount(data.item, $event)"

            >
            </BlSpinButton>
          </template>
          <!-- Цена -->
          <template #cell(price)="data">

            <span>{{ data.item.product.price | formatNumber }}</span>
          </template>
          <!-- Стоимость -->
          <template #cell(sum)="data">

            <b>
              {{ data.item.product.price * data.item.count | formatNumber }}

            </b>
          </template>

          <!-- Управление -->
          <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="openOrderItemAddCommentSidebar(data.item)">

                <feather-icon v-if="data.item.comment !== ''" icon="FileTextIcon" style="color: #55ff55"/>
                <feather-icon v-else icon="FileTextIcon"/>

                <span class="align-middle ml-50">Комментарий</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteOrderItem(data.item.id)">
                <feather-icon icon="TrashIcon"/>
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </b-card>

      <b-card no-body v-if="$store.state.app.order">
        <b-card-header class="flex-column align-items-start">
          <b-card-text class="text-muted mt-25">
            ОБЩАЯ ИНФОРМАЦИЯ ПО ЗАКАЗУ
          </b-card-text>
        </b-card-header>
        <b-card-body>
          <h6 class="card-holder-name my-75">
            Заказ № {{ $store.state.app.order.id }} от {{ $store.state.app.order.create_at | moment("DD.MM.YYYY") }}
          </h6>

          <hr class="my-2">
          <b-row>
            <b-col cols="4">
              <b-row>
                <b-col cols="6">
                  <h6 class="card-holder-name my-75">Итого</h6>
                </b-col>
                <b-col cols="6">
                  <h6 class="card-holder-name my-75">{{ $store.state.app.order.totalAmount | formatNumber }}</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <p class="card-holder-name my-75">Позиций</p>
                </b-col>
                <b-col cols="6">
                  <p class="card-holder-name my-75">{{ $store.state.app.order.totalCount }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <p class="card-holder-name my-75">Статус заказа</p>
                </b-col>
                <b-col cols="6">
                  <b-badge
                      pill
                      :variant="`light-${resolveOrderStatusVariant($store.state.app.order.status)}`"
                      class="text-capitalize"
                  >
                    {{ getStatusByCode($store.state.app.order.status) }}
                  </b-badge>

                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <p class="card-holder-name my-75">Менеджер</p>
                </b-col>
                <!-- Менеджер -->
                <b-col sm="6" class="" v-if="$store.state.app.order">
                  <b-form-select id="manager" v-model="$store.state.app.order.manager.id" :options="manager.options"
                                 :disabled="$store.state.app.order.status !== 'CREATED'"></b-form-select>

                </b-col>
              </b-row>
            </b-col>
            <b-col cols="8">
              <p>Комментарий к заказу</p>
              <b-form-textarea
                  id="textarea-rows"
                  placeholder="Напишите комментарий для менеджера"
                  rows="5"
                  v-model="$store.state.app.order.comment"
              >
              </b-form-textarea>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button
                  variant="primary"
                  class="mr-2"
                  v-if="$store.state.app.order.status === 'CREATED'"
                  @click="sendToManager($store.state.app.order.id)"
              >
                Отправить менеджеру
              </b-button>
              <b-button variant="outline-primary" @click="saveOrder"> Сохранить</b-button>

            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
      <b-sidebar
          id="add-comment-to-order-item-sidebar"
          v-model="addCommentSidebarIsVisible"
          bg-variant="white"
          sidebar-class="sidebar-lg"
          shadow
          backdrop
          no-header
          right

      >

        <template #default="{ hide }">
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
              Комментарий к товару
            </h5>

            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />


          </div>
          <div v-if="currentOrderItem!==null" class="p-2">
            <bl-text-area
                name="comment"
                label="Комментарий"
                placeholder="Напишите свой комментарий сюда..."
                v-model="currentOrderItem.comment"
            ></bl-text-area>
            <!-- Кнопки -->
            <div class="d-flex mt-2">
              <b-button
                  variant="primary"
                  class="mr-2"
                  type="button"
                  @click="saveComment()"

              >
                Сохранить
              </b-button>
              <b-button
                  type="button"
                  variant="outline-secondary"
                  @click="addCommentSidebarIsVisible=false"
              >
                Отменить
              </b-button>
            </div>

          </div>
        </template>
      </b-sidebar>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormTextarea,
  BCardTitle,
  BCardText,
  BCardHeader,
  BCardBody,
  BButton,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown, BDropdownItem, BPagination, BFormSelect,
  BOverlay, BSidebar
} from 'bootstrap-vue'
import store from "@/store";
import userStoreModule from "@/store/app/userStoreModule";
import {onUnmounted, ref} from "@vue/composition-api";
import useUsersList from "@/components/views/clients/useUsersList";
import {avatarText} from "@core/utils/filter";
import vSelect from "vue-select";
import BlInput from "@/components/controls/BlInput";
import BlSpinButton from "@/components/controls/BlSpinButton";
import BlTextArea from "@/components/controls/BlTextArea";

import api from '@/api'
import utils from "@/utils";

export default {
  name: 'OrderView',
  props: [
    'id'

  ],
  components: {
    BFormTextarea,
    BlInput,
    // BSV
    BCardHeader,
    BCardTitle,
    BCardText,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BlSpinButton,
    BFormSelect,
    BOverlay,
    BSidebar,
    BlTextArea,

    // SFC
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      {label: 'Admin', value: 'admin'},
      {label: 'Author', value: 'author'},
      {label: 'Editor', value: 'editor'},
      {label: 'Maintainer', value: 'maintainer'},
      {label: 'Subscriber', value: 'subscriber'},
    ]

    const planOptions = [
      {label: 'Basic', value: 'basic'},
      {label: 'Company', value: 'company'},
      {label: 'Enterprise', value: 'enterprise'},
      {label: 'Team', value: 'team'},
    ]

    const statusOptions = [
      {label: 'Не отправлен', value: 'CREATED'},
      {label: 'Выполнен', value: 'COMPLETED'},
      {label: 'В Работе', value: 'IN_PROGRESS'},
    ]

    const {
      fetchUsers,
      // tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusLabel,

    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      // tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusLabel,

      roleOptions,
      planOptions,
      statusOptions,
    }
  },
  data() {
    return {
      dataLoading: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        options: [10, 25, 50, 100],
        rows: 100,
      },
      manager: {
        selected: null,
        options: []
      },
      tableColumns: [
        {key: 'vendor_code', label: 'Артикул'},
        {key: 'image', label: 'Фото'},
        {key: 'name', label: 'Наименование'},
        {key: 'count', label: 'Количество'},
        {key: 'price', label: 'Цена'},
        {key: 'sum', label: 'Стоимость'},
        {key: 'actions', label: 'Управление'},
      ],
      items: [],
      addCommentSidebarIsVisible: false,
      currentOrderItem: null,

    }
  },
  mounted() {
    this.loadOrder()
    this.getOptionsManagers()
  },
  methods: {
    getImageUrl(product){
      if (!product.image){
        return '/static/images/no_photo.png'
      }
      else{
        const splitted = product.image.split('/')
        return '/uploads/products/' + splitted[splitted.length-1]
      }
      return ''
    },
    changeOrderItemCount(item, count) {
      const deltaCount = count - item.count
      item.count = count

      console.log('change to ', count, item.product.id, deltaCount)
      const self = this;
      self.dataLoading = true;
      api.orders
          .addProduct(this.id, item.product.id, deltaCount)
          .then((response) => {
            const data = response.data;

            if (data.error) {
              throw "Ошибка";
            } else {
              self.loadOrder()

            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    },
    saveOrder() {
      const self = this;
      self.dataLoading = true;
      api.orders.update(this.$store.state.app.order)
          .then(response => {
            const data = response.data;
            const order = data.result
            if (!order.manager) {
              order.manager = {}
            }
            utils.notificate(self, "Заказ успешно обновлен")
            self.dataLoading = false
            this.$store.commit('app/SET_ORDER', order)
          })
    },
    getOptionsManagers() {
      const self = this;
      self.dataLoading = true;
      api.users
          .getAllManagers()
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.manager.options = data.result.items
              for (let item of self.manager.options) {
                item.text = `${item.last_name} ${item.first_name} ${item.middle_name}`;
                item.value = item.id;
              }
              self.dataLoading = false
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    getStatusByCode(code) {
      const statusByCode = {
        CREATED: 'Не отправлен',
        COMPLETED: 'Выполнен',
        IN_PROGRESS: 'В Работе',
      }
      return statusByCode[code]


    },
    resolveOrderStatusVariant(status) {
      if (status === 'IN_PROGRESS') return 'warning'
      if (status === 'COMPLETED') return 'success'
      if (status === 'CREATED') return 'danger'
      return 'primary'
    },
    resolveOrderStatusLabel(status) {
      if (status === 'IN_PROGRESS') return 'В работе'
      if (status === 'COMPLETED') return 'Выполнен'
      if (status === 'CREATED') return 'Не отправлен'
    },
    loadOrder() {
      if (!this.id) return;
      const self = this;
      self.dataLoading = true
      api.orders.getById(this.id).then(response => {
        const order = response.data.result;
        if (!order.manager) {
          order.manager = {id: null}
        }
        self.dataLoading = false
        self.$store.commit('app/SET_ORDER', order)
      })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    },
    sendToManager(id) {
      const self = this;
      if (!this.id) return;
      self.dataLoading = true;
      api.orders.update(this.$store.state.app.order)
          .then(response => {
            const data = response.data;
            const order = data.result
            if (!order.manager) {
              order.manager = {}
            }
            utils.notificate(self, "Заказ успешно обновлен")
            this.$store.commit('app/SET_ORDER', order)
            api.orders.sendOrderToManager(id).then(response => {
              const order = response.data.result;
              if (!order.manager) {
                order.manager = {id: null}
              }
              self.dataLoading = false
              self.$store.commit('app/SET_ORDER', order)
            })
                .catch((err) => {
                  const data = err.response.data;
                  if (data && data.message) {
                    utils.notificateError(self, data.message, data.errorCode);
                  }
                });
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });


    },
    openOrderItemAddCommentSidebar(orderItem) {
      console.log(123)
      this.currentOrderItem = orderItem
      this.addCommentSidebarIsVisible = true
    },
    saveComment() {
      if (!this.currentOrderItem) return;
      const self = this;
      self.dataLoading = true
      api.orders.setComment(this.id, this.currentOrderItem.id, this.currentOrderItem.comment).then(response => {
        self.dataLoading = false
      })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    },
    deleteOrderItem(orderItemId) {
      const self = this;
      self.dataLoading = true
      api.orders.deleteOrderItem(this.id, orderItemId).then(response => {
        const data = response.data
        self.dataLoading = false
        if (!data.error) {
          self.loadOrder()
        }
      })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    }
  }
}
</script>
