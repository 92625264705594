<template>
  <form-wizard
      ref="refFormWizard"
      color="#4b4b4b"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
  >
    <tab-content title="Корзина" icon="feather icon-shopping-cart">
      <b-overlay :show="isLoading" rounded="sm">
        <step-cart
            @next-step="formWizardNextStep"
        />
      </b-overlay>
    </tab-content>

    <!-- address -->
    <tab-content title="Заказ" icon="feather icon-home">
      <step-order @next-step="formWizardNextStep"/>
    </tab-content>
  </form-wizard>
</template>

<script>
import {FormWizard, TabContent} from "vue-form-wizard";
import {BOverlay} from "bootstrap-vue";
import {ref} from "@vue/composition-api";
import StepCart from "./StepCart.vue";
import StepOrder from "./StepOrder.vue";

import api from "@/api";
import utils from "@/utils";

export default {
  components: {
    StepOrder,
    // 3rd Party
    FormWizard,
    TabContent,
    BOverlay,
    // SFC
    StepCart,
  },
  setup() {
    const refFormWizard = ref(null);

    const formWizardNextStep = () => {
      refFormWizard.value.nextTab();
    };

    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        fullName: "",
        mobile: "",
        houseNo: "",
        landmark: "",
        city: "",
        pincode: "",
        state: "",
        addressType: null,
      },
      payment: {
        cvv: "",
      },
    });

    return {
      refFormWizard,
      formWizardNextStep,

      // Dummy Details
      checkoutDetails,
    };
  },
  computed: {
    totalPrice() {
      if (!this.basket) return 0;
      let amount = 0;
      for (let item of this.basket.items) {
        amount += item.count * item.price;
      }
      return amount;
    },
  },
  mounted() {
    this.loadBasket();
  },
  data() {
    return {
      basket: null,
      isLoading: true,
    };
  },
  methods: {
    loadBasket() {
      const self = this;
      self.isLoading = true;
      api.store.getBasket().then(response => {
        const data = response.data.result;
        self.$store.commit('app/SET_CART', data)
        self.$forceUpdate()
        self.isLoading = false
      })
          .catch((err) => {
            self.isLoading = false;
          });
    },
    // addItem(product_id, count) {
    //   const self = this;
    //   self.isLoading = true;
    //   api.store
    //     .basketAddItem(product_id, count)
    //     .then((response) => {
    //       self.isLoading = false;
    //       self.loadBasket();
    //     })
    //     .catch((err) => {
    //       self.isLoading = false;
    //     });
    // },
    confirmOrder() {
      return;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "../../../../node_modules/vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
