<template>
  <div class="list-view product-checkout mt-0">
    <!-- Товары -->
    <div class="checkout-items"

    >
      <cart-product
          v-if="$store.state.app.cart.items.length > 0"
          v-for="item in $store.state.app.cart.items"
          :key="item.product.id"
          :id="item.product.id"
          :grade_of_waterproofment="item.product.grade_of_waterproofment"
          :image="item.product.image"
          :name="item.product.name"
          :power="item.product.power"
          :price="item.price"
          :vendor_code="item.product.vendor_code"
          :voltage="item.product.voltage"
          :itemCount="item.count"
      />
      <h3 v-if="$store.state.app.cart.items.length < 1" >В корзине нет товаров</h3>
    </div>

    <!-- Стоимость -->
    <div class="checkout-options">
      <b-card>
        <label class="section-label mb-1">Заказ</label>
        <hr/>
        <div class="price-details">
          <h6 class="price-title">Детализация</h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">Итого стоимость</div>
              <div class="detail-amt font-weight-bolder">
                {{ $store.state.app.cart.amount | formatNumber }}
              </div>
            </li>
          </ul>
          <b-button variant="primary" block @click="createOrder"
                    :disabled="$store.state.app.cart.items.length==0"
          >
            Разместить заказ
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import CartProduct from "./CartProduct.vue";
import api from '@/api';
import utils from '@/utils';

export default {
  props: ["basket", "totalPrice"],
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    CartProduct,
  },
  methods: {
    createOrder() {
      const self = this;
      api.store.basketToOrder()
          .then(response => {
            const data = response.data;
            const order = data.result
            if (!order.manager) {
              order.manager = {
                id: null
              }
            }
            if (data && !data.error) {
              self.$store.commit('app/SET_ORDER', order)
              self.$store.commit('app/RESET_CART')
              utils.notificate(self, "Заказ успешно создан")
              this.$emit('next-step');
            }
          })

    }
  }
};
</script>
