<template>

    <b-card
        class="ecommerce-card"
        no-body
    >
      <!-- Картинка -->
      <div class="item-img">
        <b-link>
          <b-img
              :src="imageUrl(image)"
              :alt="`${name}-${id}`"
              class="cart-item-image"
          />
        </b-link>
      </div>

      <!-- Карточка -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ name }}
            </b-link>
          </h6>

          <!-- Краткая сводка параметров -->
          <div class="item-description mb-1">
            <div class="row">
              <div class="col-12" v-if="vendor_code">
                {{ vendor_code }} Артикул
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span v-if="power">{{ power }}W </span
                ><br/>
                <span v-if="voltage"
                >{{ voltage }}V</span
                ><br/>
                <span v-if="grade_of_waterproofment"
                >IP{{ grade_of_waterproofment }}</span
                ><br/>
              </div>
            </div>
          </div>
        </div>

        <div class="item-quantity">
          <span class="quantity-title">Количество:</span>
          <BlSpinButton
              :value="itemCount"
              @input="changeValue($event, itemCount)"


          >
          </BlSpinButton>
        </div>
      </b-card-body>

      <!-- Кнопки -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">{{ price | formatNumber }}</h4>
          </div>
        </div>
        <b-button
            variant="light"
            class="mt-1 remove-wishlist"
            @click="addItem(id, -itemCount)"
        >
          <feather-icon icon="XIcon" class="mr-50"/>
          <span>Удалить</span>
        </b-button>
      </div>
    </b-card>

</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormSpinbutton,
} from "bootstrap-vue";
import BlSpinButton from "@/components/controls/BlSpinButton";
import store from "@/store";
import {ref} from "@vue/composition-api";
import {formatDate} from "@core/utils/filter";
import {useEcommerce, useEcommerceUi} from "@/store/app/useEcommerce";
import api from "@/api";

export default {
  name: "card-product",
  props: [
    "name",
    "id",
    "vendor_code",
    "power",
    "voltage",
    "grade_of_waterproofment",
    "itemCount",
    "image",
    "price",
  ],
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
    BlSpinButton,
  },
  methods: {
    imageUrl(image) {
      if (image) {
        return image;
      } else {
        return '/static/images/no_photo.png'
      }

    },
    changeValue(newValue, oldValue) {
      this.addItem(this.id, newValue - oldValue)
    },
    addItem(product_id, count) {
      const self = this;
      self.isLoading = true;
      api.store
          .basketAddItem(product_id, count)
          .then((response) => {
            self.isLoading = false;
            const data = response.data.result;
            self.$store.commit('app/SET_CART', data)
            self.$forceUpdate()
          })
          .catch((err) => {
            self.isLoading = false;
          });
    },

  },

  // setup() {
  //   const products = ref([]);

  //   const { toggleProductInWishlist } = useEcommerceUi();
  //   const { removeProductFromCart } = useEcommerce();
  //   const removeProductFromCartClick = (product) => {
  //     removeProductFromCart(product.id).then(() => {
  //       const productIndex = products.value.findIndex(
  //         (p) => p.id === product.id
  //       );
  //       products.value.splice(productIndex, 1);

  //       store.commit(
  //         "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
  //         products.value.length
  //       );
  //     });
  //   };

  //   const fetchCartProducts = () => {
  //     store.dispatch("app-ecommerce/fetchCartProducts").then((response) => {
  //       products.value = response.data.products;
  //     });
  //   };
  //   fetchCartProducts();

  //   return {
  //     products,

  //     // UI
  //     toggleProductInWishlist,
  //     removeProductFromCartClick,

  //     // Filter
  //     formatDate,
  //   };
  // },
};
</script>

<style>
.cart-item-image {
  max-height: 130px;
  object-fit: cover;
}
</style>
