<template>
  <order-view :id="$store.state.app.order.id"></order-view>
</template>

<script>
import {
  BFormTextarea,
  BCardTitle,
  BCardText,
  BCardHeader,
  BCardBody,
  BButton,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import CartProduct from './CartProduct.vue'
import store from "@/store";
import userStoreModule from "@/store/app/userStoreModule";
import {onUnmounted, ref} from "@vue/composition-api";
import useUsersList from "@/components/views/clients/useUsersList";
import {avatarText} from "@core/utils/filter";
import vSelect from "vue-select";
import BlInput from "@/components/controls/BlInput";
import BlSpinButton from "@/components/controls/BlSpinButton";
import OrderView from "@/components/views/orders/OrderView";
import api from '@/api'

export default {
  components: {
    BFormTextarea,
    BlInput,
    // BSV
    BCardHeader,
    BCardTitle,
    BCardText,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BlSpinButton,

    // SFC
    CartProduct,
    OrderView,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      {label: 'Admin', value: 'admin'},
      {label: 'Author', value: 'author'},
      {label: 'Editor', value: 'editor'},
      {label: 'Maintainer', value: 'maintainer'},
      {label: 'Subscriber', value: 'subscriber'},
    ]

    const planOptions = [
      {label: 'Basic', value: 'basic'},
      {label: 'Company', value: 'company'},
      {label: 'Enterprise', value: 'enterprise'},
      {label: 'Team', value: 'team'},
    ]

    const statusOptions = [
      {label: 'На модерации', value: 'pending'},
      {label: 'Активен', value: 'active'},
      {label: 'Неактивен', value: 'inactive'},
    ]

    const {
      fetchUsers,
      // tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusLabel,

    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      // tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusLabel,

      roleOptions,
      planOptions,
      statusOptions,
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        options: [10, 25, 50, 100],
        rows: 100,
      },
      tableColumns: [
        {key: 'vendor_code', label: 'Артикул'},
        {key: 'image', label: 'Фото'},
        {key: 'name', label: 'Наименование'},
        {key: 'count', label: 'Количество'},
        {key: 'price', label: 'Цена'},
        {key: 'sum', label: 'Стоимость'},
        {key: 'actions', label: 'Управление'},
      ],
      items: [
        {
          article: '00001',
          image: require('@/assets/images/pages/eCommerce/25.png'),
          name: 'Тестовый светильник',
          count: 1,
          price: 200,
          sum: 200,
        },
        {
          article: '00002',
          image: require('@/assets/images/pages/eCommerce/23.png'),
          name: 'Тестовый светильник #2',
          count: 1,
          price: 300,
          sum: 300,
        }
      ]
    }
  },
  mounted() {
    // this.loadOrder()
  },
  methods: {
    resolveOrderStatusVariant(status) {
      if (status === 'pending') return 'warning'
      if (status === 'active') return 'success'
      if (status === 'inactive') return 'danger'
      return 'primary'
    },
    resolveOrderStatusLabel(status) {
      if (status === 'pending') return 'В работе'
      if (status === 'active') return 'Выполнен'
      if (status === 'inactive') return 'Не отправлен'
    },
    // loadOrder() {
    //   const self = this;
    //   api.orders.getById(this.id).then(response => {
    //     const order = response.data.result;
    //     self.$store.commit('app/SET_ORDER', order)
    //   })
    //
    // }
  }
}
</script>
